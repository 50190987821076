import { Fragment, useState } from "react";
import logo from "../assets/logo2.png";
import { navOptions } from "../static-data";
import { FiPhoneCall } from "react-icons/fi";
import { GiHamburgerMenu } from "react-icons/gi";
import MobileMenu from "../components/MobileMenu";

export default function NavBar() {
  const [mobileMenu, setMobileMenu] = useState(false)
  const menu = (data) => {
    setMobileMenu(data)
  }
  return (
    <Fragment>
      <div className="flex items-center lg:px-20 sm:px-12 phone:px-4 gap-20 justify-between py-6 border-b border-neutral-700 w-full">
        <div>
          <img src={logo} alt="logo" width={200} />
        </div>
        <div className="flex items-center">
          <ul className="lg:flex items-center text-base font-medium xl:gap-14 lg:gap-8 phone:hidden">
            {/* {navOptions.map((opt, index) => (
                <li key={index}>
                  <a href={opt.link}>{opt.option}</a>
                </li>
            ))} */}
          </ul>
          {/* Mobile Menu */}
          <span
            onClick={() => setMobileMenu(true)}
            className="flex bg-black rounded-full p-2 w-max cursor-pointer"
          >
            <GiHamburgerMenu size={24} />
          </span>
        </div>
      </div>
      {mobileMenu && <MobileMenu menu={menu} />}
    </Fragment>
  );
};

