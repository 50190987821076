import { NavLink } from "react-router-dom";
import { navOptions } from "../static-data";


export default function MobileMenu(props) {
  const linkStyle = "text-black my-4";
  return (
    <>
      <div className="flex flex-col fixed top-0 left-0 h-full w-full">
        <div
          onClick={() => props.menu(false)}
          className="flex bg-[#000000c7] w-full h-full absolute left-0 top-0"
        ></div>
        <div className="sm:w-[320px] phone:w-[250px] bg-white z-10 h-full">
          <h2 className="m-10 text-black font-bold text-h4">Menu</h2>
          <div className="flex flex-col mx-10">
            {navOptions?.map(val => (
              <NavLink to={val.link} className={linkStyle}>
                {val.option}
              </NavLink>
            ))}
            <span className="flex text-xs text-black50 w-full absolute bottom-4">
              DailogMakers-International Ltd.
            </span>
          </div>
        </div>
      </div>
    </>
  );
}
