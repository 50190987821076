import { FaRocket } from "react-icons/fa6";
import { SiMaterialdesignicons } from "react-icons/si";
import { HiOutlineLightBulb } from "react-icons/hi";
import { FaComputer } from "react-icons/fa6";
import { FaPlateWheat } from "react-icons/fa6";


export const navOptions = [
  {
    option: "Home",
    link: "/",
  },
  {
    option: "Services",
    link: "/services",
  },
  {
    option: "Team",
    link: "/team",
  },
  {
    option: "Pricing",
    link: "/pricing",
  },
  {
    option: "Portfolio",
    link: "/portfolio",
  },
  {
    option: "Contact",
    link: "/contact",
  },
  // {
  //   option: "Shop",
  //   link: "/shop",
  // },
  {
    option: "About",
    link: "/about-us",
  },
 
];

export const services = [
  {
    icon: <SiMaterialdesignicons className="service-icon"/>,
    title: "UI / UX CREATIVE DESIGN",
  },
  {
    icon: <FaRocket className="service-icon"/>,
    title: "VISUAL GRAPHIC DESIGN",
  },
  {
    icon: <HiOutlineLightBulb className="service-icon"/>,
    title: "STRATEGY & MARKETING",
  },
  {
    icon: <FaPlateWheat className="service-icon"/>,
    title: "CONTENT WRITTING SKILLS",
  },
  {
    icon: <FaComputer className="service-icon"/>,
    title: "WEB & APP DEVELOPMENT",
  },
];


export const teamInfo = [
  {
    profile: "https://dialogmakers-international.com/img/team/Joe.png",
    name: "Chao Ching HSU",
    profession: "Director & Founder",
    fb: "",
    twitter: "",
  },
  {
    profile: "https://dialogmakers-international.com/img/team/Eric.png",
    name: "Eric Ronnau",
    profession: "CEO & Founder",
    fb: "",
    twitter: "",
  },
  {
    profile: "https://dialogmakers-international.com/img//team/Sumit.png",
    name: "Sumit Chauhan",
    profession: "Full Stack Developer",
    fb: "",
    twitter: "",
  },
  {
    profile: "https://dialogmakers-international.com/img/team/Nilshan.png",
    name: "Nilshan Alagujara",
    profession: "Frontend Developer",
    fb: "",
    twitter: "",
  },
  {
    profile: "https://dialogmakers-international.com/img/team/Nichole.png",
    name: "Russel Nicole Bureros",
    profession: "Senior Project Manager",
    fb: "",
    twitter: "",
  },
  {
    profile: "https://dialogmakers-international.com/img//team/Issabella.png",
    name: "Isabelle Ratajczak",
    profession: "Project Manager",
    fb: "",
    twitter: "",
  },
  {
    profile: "https://dialogmakers-international.com/img/team/Bineeesh.png",
    name: "Binesh",
    profession: "Server Administrator",
    fb: "",
    twitter: "",
  },
  {
    profile: "https://dialogmakers-international.com/img/team/Ishan.png",
    name: "Ishan Bandara",
    profession: "Graphic Designer",
    fb: "",
    twitter: "",
  },
  {
    profile: "https://dialogmakers-international.com/img//team/Amit.png",
    name: "Amit",
    profession: "Graphic Designer",
    fb: "",
    twitter: "",
  },
];
