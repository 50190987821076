import {
  FaEnvelope,
  FaFacebook,
  FaInstagram,
  FaPhone,
  FaPinterest,
  FaTwitter,
  FaLinkedinIn,
  FaSkype,
} from "react-icons/fa";
import { TfiLocationPin } from "react-icons/tfi";
import logo from "../assets/logo2.png";
import useDevice from "../useDevice";
import { Link } from "react-router-dom";

const Footer = () => {
  const { isBigMobile } = useDevice();
  return (
    <>
      <div className="relative">
        <div className="bg-[rgba(0,0,0,0.9)] absolute top-0 left-0 bottom-0 right-0">
          <div className="footer opacity-5"></div>
        </div>
        <div
          className={`relative text-white px-4 md:py-12 py-20 xl:max-w-[1200px] md:max-w-[700px] lg:max-w-[950px]  mx-auto ${
            isBigMobile && "pl-16 pr-16"
          }`}
        >
          <div className="md:flex items-center justify-between border-b pb-12 border-neutral-700">
            <div>
              <img src={logo} alt="dialogmakers-logo" width={200} />
            </div>
            <div className="flex gap-2 mt-8 md:mt-0">
              <div className="bg-[rgba(0,0,0,0.4)] p-4 rounded-[50%] text-2xl">
                <a href="https://www.facebook.com/DialogmakersGlobal">
                  <FaFacebook />
                </a>
              </div>
              <div className="bg-[rgba(0,0,0,0.4)] p-4 rounded-[50%] text-2xl">
                <a href="https://twitter.com/_Dialogmakers">
                  <FaTwitter />
                </a>
              </div>
              <div className="bg-[rgba(0,0,0,0.4)] p-4 rounded-[50%] text-2xl">
                <a href="https://www.instagram.com/dialogmakers_global/">
                  <FaInstagram />
                </a>
              </div>
              <div className="bg-[rgba(0,0,0,0.4)] p-4 rounded-[50%] text-2xl">
                <a href="https://www.linkedin.com/in/dialogmakers-international-7b2398201">
                  <FaLinkedinIn />
                </a>
              </div>
            </div>
          </div>
          <div className="flex gap-16 mt-8 phone:flex-col lg:flex-row">
            <div className="">
              <h2 className="text-h6 font-bold text-primary">Address</h2>
              <ul className="w-[80%] text-black75">
                <li className="mt-4 flex items-start gap-4">
                  <TfiLocationPin size={30} />
                  2F, No. 2-1, Lane 23, Wenhua Street, Pingzhen District,
                  Taoyuan City, 324 Taiwan
                </li>
                <li className="mt-4 flex items-start gap-4">
                  <FaSkype size={28} />
                  ---
                </li>
              </ul>
            </div>
            <div className="">
              <h2 className="text-h6 font-bold text-primary">Quick Links</h2>
              <ul className="text-black75 mt-4">
                <li className="mt-3">
                  <a href="/about-us">About</a>
                </li>
                <li className="mt-3">
                  <a href="/pricing">Pricing</a>
                </li>
                <li className="mt-3">
                  <a href="portfolio">Portfolio</a>
                </li>
                <li className="mt-3 whitespace-nowrap">
                  <a href="#" className="flex gap-4 items-center">
                    <FaEnvelope /> support@dialogmakers-international.com
                  </a>
                </li>
              </ul>
            </div>
            <div className="">
              <h2 className="text-h6 font-bold text-primary">Legal Pages</h2>
              <ul className="text-black75 mt-4">
                <li className="mt-3">
                  <a href="/imprint">Imprint</a>
                </li>
                <li className="mt-3">
                  <a href="/data-saving-rule">Privacy Policy</a>
                </li>
                <li className="mt-3 whitespace-nowrap">
                  <Link to="/terms-and-condition">Terms and Conditions</Link>
                </li>
                <li className="mt-3"></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-[#0c0c0d] text-white text-center py-8 text-font">
        <span className="opacity-40">
          © Copyright 2024 by DialogMakers International Ltd.
        </span>
      </div>
    </>
  );
};

export default Footer;
