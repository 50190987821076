import React from "react";
import PageInto from "../components/PageIntro";

const DataSavingRule = () => {
  return (
    <>
      <PageInto pageName={"Privacy Policy"} />

      <div className="xl:max-w-[1200px] md:max-w-[700px] lg:max-w-[950px] mx-auto px-4 py-8">
        <div className="">
          <br />
          <p className="mb-4 text-center">Dialogmakers International Ltd.</p>
          <p className="mb-4 text-center">
            71-75 Shelton Street, Covent Garden, London, WC2H 9JQ
          </p>

          <p className="mb-4 text-center text-blue-500">
            <span className="font-bold text-black">Email :</span>{" "}
            support@dialogmakers-international.com
          </p>

          <div className="pt-6">
            <h2 className="text-xl font-bold mb-2 text-center">Introduction</h2>

            <p className="mb-4 text-center">
              (hereinafter “Dialogmakers International Ltd” or “we/us”) operates
              a platform and service for chatting, getting to know and meeting
              other people (hereinafter “Dialogmakers International Ltd”) under
              the domain DialogmakersInternationalLtd.com and with the help of
              the mobile app Dialogmakers International Ltd (hereinafter
              “Dialogmakers International Ltd app”), which is available for
              download in various app stores for various operating systems. The
              user of Dialogmakers International Ltd (in the following: "user"
              or "you") can create a profile (in the following: "user profile")
              free of charge via the Dialogmakers International Ltd domain or
              Dialogmakers International Ltd app, upload pictures and exchange
              information with other users and their user profiles in case of
              interest. The user has the possibility to use functions such as
              the chat, the flirt radar or the match game and many other
              functions of Dialogmakers International Ltd. A registration is
              possible from the age of 18. Your data will be collected,
              processed and used in accordance with the EU-General Data
              Protection Regulation (GDPR) 2016/679 applicable as of May 25th
              2018 and the related privacy laws. This privacy policy explains
              how we handle personal data. Personal data is individual
              information about personal or factual circumstances of a certain
              or determinable natural person. This includes, for example, name,
              birthday, telephone number, but also e-mail address and usage
              data, such as IP address. Furthermore, the data protection
              declaration serves to inform about which data is collected, stored
              and processed and how Dialogmakers International Ltd guarantees
              the protection and security of personal data. Dialogmakers
              International Ltd does not enforce the use of a real name. Instead
              of a name, the user can register with and make use of Dialogmakers
              International Ltd under a pseudonym.
            </p>
          </div>
          <div className="pt-6">
            <h2 className="text-xl font-bold mb-2 text-center">
              1. How will my data be collected, processed and used upon visiting
              the Dialogmakers International Ltd or the Dialogmakers
              International Ltd website without creating a user profile
            </h2>

            <p className="mb-4 text-center">
              Dialogmakers International Ltd uses the personal data that the
              user provides or that incurred in the use of Dialogmakers
              International Ltd without creating a user profile, without
              separate consent, solely for the implementation of the user
              relationship and as presented in this privacy policy. Upon
              visiting the Dialogmakers International Ltd domain (to a so-called
              “landing page” or “homepage”) or installing the Dialogmakers
              International Ltd app without the creation of a user profile,
              Dialogmakers International Ltd collects the following data
              transmitted by the user’s device/browser: IP address, name of the
              website accessed, data, date and time of the request, transferred
              data volume, browser type and version, referrer URL (original URL)
              of the user who accessed the page. Dialogmakers International Ltd
              also collects the following data transmitted by the user’s device
              upon simple installation of the Dialogmakers International Ltd app
              without registration or creation of a user profile: Push handle
              (for sending push notifications), location data (GPS location),
              country code, language, device name, name and version of the
              operating system. These data are necessary for the use of the
              Dialogmakers International Ltd domain or the installation of the
              Dialogmakers International Ltd app, and as such are necessary for
              statistical purposes and to optimize Dialogmakers International
              Ltd. The data is processed and used to prevent and combat illegal
              acts and spam as well as to ensure the stability and integrity of
              the Dialogmakers International Ltd platform. The location data is
              collected, processed, and used so that the user can make use of
              the so-called location-based service, which includes tailored
              recommendations for the respective location. For more details,
              please check 3.3 and 4.3 of this policy. Furthermore, Dialogmakers
              International Ltd uses cookies (see item 4.1), analytics services,
              and tracking providers (see item 4.2).
            </p>
          </div>
          <div className="pt-6">
            <h2 className="text-xl font-bold mb-2 text-center">
              2. How is my data collected, processed and used after creating a
              user profile and upon use of the Dialogmakers International Ltd
              domain services?
            </h2>

            <p className="mb-4 text-center">
              Dialogmakers International Ltd uses the personal data that the
              user communicates or that becomes available when using
              Dialogmakers International Ltd after creating a user profile,
              solely for the implementation of the user relationship and as
              presented in this privacy policy. For such use, your additional
              consent is not required. The creation of a user profile is
              necessary to be able to use Dialogmakers International Ltd to its
              full extent and you herewith agree to this. In addition, you may
              provide further personal data, which Dialogmakers International
              Ltd may use in order to provide the respective service and the
              best user experience.
            </p>
          </div>
          <div className="pt-6">
            <h2 className="text-xl font-bold mb-2 text-center">
              2.1 Mandatory details for the creation of a user profile
            </h2>

            <p className="mb-4 text-center">
              For the creation of a user profile, the following details are
              essential and shall be provided:
            </p>

            <ul className=" items-center flex justify-center flex-col">
              <li>Gender</li>
              <li>Age</li>
              <li>User name (pseudonym)</li>
              <li>City</li>
              <li>E-mail address</li>
              <li>Password</li>
            </ul>

            <p className="mb-4 text-center">
              The data provided by you will be collected, processed, and used
              for the purpose of using Dialogmakers International Ltd. Without
              limitation, these details serve for the mandatory data like
              personal address, authentication, age verification, profile
              personalization, and the display of suitable user profiles. They
              may also be used in connection with and for advertising purposes
              (see item 4).
            </p>
          </div>

          <div className="pt-6">
            <h2 className="text-xl font-bold mb-2 text-center">
              2.2 Voluntary data
            </h2>

            <p className="mb-4 text-center">
              In addition, you can provide various voluntary details in your
              user profile. This serves, in particular, for the Dialogmakers
              International Ltd function whereby recommendations for other user
              profiles according to various criteria are selected for the user
              and displayed within Dialogmakers International Ltd. Additional
              information increases the chance of matching and meeting suitable
              user profiles. These details may be provided by you optionally and
              can be viewed, entered, modified, or deleted by you at any time in
              the profile under Details. The data provided is collected,
              processed, and used by us for the purpose of delivering the
              Dialogmakers International Ltd service. These voluntary details
              are visible to all users logged in within Dialogmakers
              International Ltd but can be deleted or modified at any time by
              the owner of the user profile under the settings in the user
              profile.
            </p>
          </div>
          <div className="pt-6">
            <h2 className="text-xl font-bold mb-2 text-center">
              2.3 Location data
            </h2>

            <p className="mb-4 text-center">
              Dialogmakers International Ltd also includes so-called
              location-based services, under which the user will have access to
              special functions that are tailored to the respective location.
              Hereby, the user or, in particular, other users who are around and
              nearby can be displayed. In order to offer these functions within
              the Dialogmakers International Ltd app, Dialogmakers International
              Ltd collects location data via the GPS of the device in use as
              well as location data via radio access nodes. When the location
              service is active, with permission, the location will be regularly
              transmitted to Dialogmakers International Ltd, where it will be
              processed and used. The user can modify this function in the
              operating system settings on their device at any time. This means
              to permit or revoke the acquisition of location data.
            </p>
          </div>
          <div className="pt-6">
            <h2 className="text-xl font-bold mb-2 text-center">
              2.4 IP addresses
            </h2>

            <p className="mb-4 text-center">
              User IP addresses are recorded for security and testing purposes.
              This should prevent any misuse of the service. The data is
              processed and used to prevent and combat illegal activities and
              spam as well as securing the integrity and stability of the
              Dialogmakers International Ltd platform.
            </p>
          </div>
          <div className="pt-6">
            <h2 className="text-xl font-bold mb-2 text-center">
              2.5 Payment data
            </h2>

            <p className="mb-4 text-center">
              Any in-app purchases or the purchase of so-called 'Flirts' will be
              charged by external payment providers. No payment data is
              collected or processed by Dialogmakers International Ltd for any
              of such purchases. The entry as well as the processing of the
              payment data is solely by the payment provider. Technical data
              (the transaction ID and other) is exchanged between Dialogmakers
              International Ltd and the chosen payment provider in order to
              validate the purchase.
            </p>
          </div>
          <div className="pt-6">
            <h2 className="text-xl font-bold mb-2 text-center">
              3 Cookies and cookie policy
            </h2>

            <p className="mb-4 text-center">
              In order to be able to use the Dialogmakers International Ltd
              domain as comfortably as possible, we use cookies on the
              Dialogmakers International Ltd domain. Cookies are small text
              files that are stored locally in the cache of the Internet browser
              (so-called session cookies) or on the hard disk of the mobile
              device or computer used (so-called permanent cookies). The browser
              settings can be selected so that cookies are rejected or a
              reference is made to them before cookies are set. By setting
              cookies, users are assigned pseudonymized IDs. Cookies do not run
              programs on the user's end device or even transmit viruses.
              Session cookies are deleted from the cache after the end of the
              browser session. Dialogmakers International Ltd also uses cookies
              to increase the security of the Dialogmakers International Ltd
              domain. For example, to authenticate the user during a session, to
              avoid cross-site scripting, or to prevent phishing and fraudulent
              actions such as scamming. Furthermore, tracking providers/analysis
              services (see also the explanations under point 4.2 Use for the
              use of tracking providers/analysis services) place cookies on the
              user's device on behalf of Dialogmakers International Ltd in order
              to record the usage data. This is data that can be collected while
              the user is, for example, moving on the pages of the Dialogmakers
              International Ltd domain or clicking on advertising. These cookies
              are used to display targeted advertising, optimize reports on
              advertising campaigns, and prevent identical advertising from
              being displayed multiple times. Users can influence the use of
              cookies. Most browsers have a setting option with which the
              storage of cookies is restricted or completely prevented. However,
              we would like to point out that the use and, in particular, the
              comfort of use of Dialogmakers International Ltd can be restricted
              without the use of cookies.
            </p>
          </div>
          <div className="pt-6">
            <h2 className="text-xl font-bold mb-2 text-center">
              3.1 Tracking providers, analytics services
            </h2>

            <p className="mb-4 text-center">
              Dialogmakers International Ltd carries out user tracking analytics
              in order to optimize the Dialogmakers International Ltd services
              and user experience. Dialogmakers International Ltd implements
              analytical methods that enable Dialogmakers International Ltd to
              analyze visits to the Dialogmakers International Ltd domain and
              Dialogmakers International Ltd app. Furthermore, third-party
              tracking tools are integrated to analyze the range of different
              advertising campaigns and marketing activities. Personal data can
              also be transmitted with the use of third-party tools. In addition
              to troubleshooting, the purpose of data processing is the
              optimization of Dialogmakers International Ltd with regard to user
              experience. With web analysis methods, Dialogmakers International
              Ltd also obtains statistics on visitor numbers, their temporal
              browsing of popular content, as well as the duration of visits per
              click and page. When appropriate, it can be tracked whether a user
              profile was created subsequent to viewing an advertisement. The
              data is processed and used to prevent and combat fake profiles,
              unlawful activities, and spam, as well as to ensure the security
              of the Dialogmakers International Ltd platform. User data is
              transmitted to various third parties to carry out the analysis
            </p>
          </div>
          <div className="pt-6">
            <h2 className="text-xl font-bold mb-2 text-center">
              The following analysis and tracking providers are currently in use
              with FlirtTool Prime : Crashlytics
            </h2>

            <p className="mb-4 text-center">
              The Dialogmakers International Ltd app uses the Firebase
              Crashlytics analysis tool by Google Ireland Limited, Gordon House,
              Barrow Street, Dublin 4, Ireland (hereinafter “Crashlytics”). This
              analysis program uses IP addresses of the users for analysis
              purposes, which are used only anonymously. It is not possible to
              trace a specific person. Crashlytics delivers analysis to
              Dialogmakers International Ltd on system crashes and thereby
              facilitates maintenance and improvement of the Dialogmakers
              International Ltd app and domain. A user ID, the clicks, and the
              technical device data are transmitted to analyze the error. If you
              have voluntarily and expressly agreed to submit a crash report in
              the app settings or after a crash, anonymous information (device
              type, version of the operating system, hardware data of the mobile
              device, current position in the source code, time of the crash,
              and the state of the application at the time of the crash) will be
              transmitted to the servers of Google and stored for evaluation.
              Crash reports contain no personal information. For more
              information about privacy, visit{" "}
              <a
                className="text-blue-500"
                href="https://firebase.google.com/support/privacy"
                target="_blank"
              >
                this link
              </a>
              .
            </p>
          </div>
          <div className="pt-6">
            <h2 className="text-xl font-bold mb-2 text-center">
              Google Analytics
            </h2>

            <p className="mb-4 text-center">
              Dialogmakers International Ltd uses 'Google Analytics,' a service
              by Google Ireland Limited, Gordon House, Barrow Street, Dublin 4,
              Ireland (hereinafter: 'Google'). Dialogmakers International Ltd
              points out that for use with Dialogmakers International Ltd,
              Google Analytics has been extended with the IP masking function
              '_gat._anonymizeIp' to guarantee the recording of anonymized IP
              addresses. The following information on the use of Google
              Analytics should be noted: Google Analytics uses cookies that are
              stored on the device and enable an analysis of the use of
              Dialogmakers International Ltd. The usage information produced by
              the cookie is generally transmitted to a Google server in the USA
              for storage. However, since IP anonymization is active, the IP
              addresses of all users in the EU and associated countries are
              shortened by Google prior to transmission to the USA. Under
              contract between Dialogmakers International Ltd and Google, Google
              uses the information to evaluate the use of our services, to
              compile reports on activities, and to provide the operator with
              further services. The user has the option to opt out: Google
              Analytics data acquisition and storage can be revoked at any time
              with future effect. The user also has the option to install a
              browser plugin issued by Google. This is available for various
              browser versions and can be downloaded here. You can learn more
              about data usage for advertising purposes, settings, and opting
              out on the following Google webpages: (“Google’s data usage when
              you use our partners’ websites and apps”), (“Data usage for
              advertising purposes”), (“Data usage for advertising purposes”)
              and (“Determine what advertising Google places”).
            </p>
          </div>
          <div className="pt-6">
            <h2 className="text-xl font-bold mb-2 text-center">Adjust</h2>

            <p className="mb-4 text-center">
              Dialogmakers International Ltd uses the app 'adjust,' a product of
              the company adjust GmbH, Saarbrücker Str. 37a in 10405 Berlin,
              Germany, as a usage analysis tool. When the user installs the
              Dialogmakers International Ltd app, adjust stores installation and
              event data from the iOS or Android app. Using this, we can
              understand how our users interact with the Dialogmakers
              International Ltd app. Moreover, we can analyze and improve our
              mobile advertising campaigns. Adjust uses the IDFA or advertiser
              ID as well as your anonymized ID and MAC address. The data is
              unilaterally made anonymous, meaning that it is not possible to
              identify you or your mobile device. If you don’t want adjust
              tracking, please select{" "}
              <a href="https://dialogmakers-international.com/" target="_blank">
                here
              </a>
              .
            </p>
          </div>
          <div className="pt-6">
            <h2 className="text-xl font-bold mb-2 text-center">Rapidmail</h2>

            <p className="mb-4 text-center">
              Send out of newsletters and other emails is carried out by our
              email marketing service provider rapidmail GmbH, Augustinerplatz
              2, 79098 Freiburg i.Br., Germany. You can see the privacy policy
              of the email marketing service provider here.. The email marketing
              service is used on the basis of our legitimate interests in
              accordance with Article 6, paragraph 1, point f of the GDPR and a
              processor contract in accordance with Article 28, paragraph 3 of
              the GDPR.
            </p>
          </div>
          <div className="pt-6">
            <h2 className="text-xl font-bold mb-2 text-center">
              Google Firebase
            </h2>

            <p className="mb-4 text-center">
              Furthermore, we use the service “Google Firebase” for the analysis
              and characterization of our user groups and the delivery of push
              notifications. You can find Firebase’s privacy policy here (insert
              link) as well as Google’s privacy policy
            </p>
          </div>
          <div className="pt-6">
            <h2 className="text-xl font-bold mb-2 text-center">Fyber</h2>

            <p className="mb-4 text-center">
              For app monetization, we use Fyber, a Fyber GmbH service from
              Berlin . The company Fyber also sets cookies. We do not use Fyber
              via JavaScript integration. Your IP address is not made
              accessible. Fyber only receives pseudonymized data from us for
              billing purposes. The data cannot be used by Fyber to trace a
              specific person. Further details on data protection can be found
            </p>
          </div>
          <div className="pt-6">
            <h2 className="text-xl font-bold mb-2 text-center">
              Google-Tag-Manager
            </h2>

            <p className="mb-4 text-center">
              We use Google Tag Manager. Google Tag Manager is a solution, with
              which marketers can manage website tags via an interface. The Tag
              Manager tool itself is a domain without cookies and the tool does
              not collect any personal data. The tool triggers other tags, which
              for their part may collect data. Google Tag Manager does not
              access this data. If disabled at the domain or cookie level, this
              remains the case for all tracking tags that were implemented with
              Google Tag Manager.
            </p>
          </div>
          <div className="pt-6">
            <h2 className="text-xl font-bold mb-2 text-center">
              Google Adwords
            </h2>

            <p className="mb-4 text-center">
              We use the online advertising program “Google AdWords” and
              conversion tracking within the framework of Google AdWords. Google
              Conversion Tracking is an analytical service by Google Ireland
              Limited, Gordon House, Barrow Street, Dublin 4, Irland ("Google").
              When you click on an advertisement placed by Google, a cookie for
              the conversion tracking will be placed on your computer. The
              cookies have limited validity, contain no personal data and do not
              enable a personal identification. If you visit certain internet
              pages on our website and the cookie has not yet expired, we and
              Google can recognize that you clicked on the advertisement and
              were redirected to this site. Every Google AdWords customer
              receives a different cookie. It is therefore not possible for
              cookies to be tracked over the websites of Google AdWords
              customers. The information obtained with the help of the
              conversion cookie serves for the creation of conversion statistics
              for AdWords customers, who have selected conversion tracking.
              Hereby, the customers learn the total number of users that have
              clicked on their advert and have been redirected to a page with a
              conversion tracking tag. They receive, however, no information
              that can be used to personally identify users. If you do not want
              to participate in tracking, you can opt out by blocking the
              installation of cookies through a corresponding setting in your
              browser software (deactivation option). Upon opting out you will
              not be recorded in the conversion tracking statistics. You can
              find further information as well as Google’s privacy policy
            </p>
          </div>
          <div className="pt-6">
            <h2 className="text-xl font-bold mb-2 text-center">
              Google ReCaptcha
            </h2>

            <p className="mb-4 text-center">
              We integrate the function for the recognition of bots
              (“ReCaptcha”), e.g. for entries in online forms, offered by Google
              Ireland Limited, Gordon House, Barrow Street, Dublin 4, Ireland.
              You have the right to
            </p>
          </div>
          <div className="pt-6">
            <h2 className="text-xl font-bold mb-2 text-center">
              Facebook Pixel and SDK
            </h2>

            <p className="mb-4 text-center">
              Dialogmakers International Ltd integrates the function for the
              recognition of bots (“ReCaptcha”), e.g., for entries in online
              forms, offered by Google Ireland Limited, Gordon House, Barrow
              Street, Dublin 4, Ireland. You have the right to opt out. We use
              the 'Facebook Pixel' on our websites {""}
              <a
                className="text-blue-500"
                href="https://dialogmakers-international.com/"
                target="_blank"
              >
                https://dialogmakers-international.com/
              </a>
              . and in our Dialogmakers International Ltd app, the 'Software
              Developer Kit' (SDK) of the social network Facebook, 1601 South
              California Avenue, Palo Alto, CA 94304, United States. These
              counting pixels are integrated on our pages and in the
              Dialogmakers International Ltd app. When visiting our websites and
              using our app, a direct connection between your browser or mobile
              device and the Facebook server is established. Facebook receives
              the information from your browser that our website has been
              accessed from your device. If you are a Facebook user, Facebook
              may assign your visit to our sites and the use of our app to your
              account. We point out that we are not aware of the content of the
              transmitted data and their use by Facebook. We can only choose
              which category of Facebook users (such as age, interests) our ad
              should display. By calling the pixel from your browser, Facebook
              can also determine if a Facebook ad was successful. In this way,
              we record the effectiveness of Facebook advertisements for
              statistical and market research purposes. We use the data obtained
              from the Facebook pixel in the Facebook analysis tool 'Facebook
              Analytics' to optimize our websites and our app. For more
              information, see the Facebook Privacy Policy at {""}
              <a
                className="text-blue-500"
                href=" https://www.facebook.com/about/privacy/."
                target="_blank"
              >
                here
              </a>{" "}
              {""}
              Please click on the following link if you do not want to collect
              data via Facebook Pixel: Opt out of Facebook Pixel Tracking
            </p>
          </div>
          <div className="pt-6">
            <h2 className="text-xl font-bold mb-2 text-center">
              Social media links
            </h2>

            <p className="mb-4 text-center">
              Links to Dialogmakers International Ltd accounts with the social
              networks Instagram and Facebook are incorporated on the
              Dialogmakers International Ltd domain. After clicking on the
              integrated graphic, the user will be redirected to the respective
              provider of Instagram or Facebook. This means that only then will
              user information be transmitted to the respective provider. If the
              user is logged in to their user profile on the respective social
              network, after activation of the button, an association with the
              Dialogmakers International Ltd visit is made. If the user does not
              want data to be collected via the Dialogmakers International Ltd
              domain by the social networks, they should log out of these before
              visiting the Dialogmakers International Ltd domain. However, every
              time the corresponding button is activated through a click, a
              cookie or cookies with an identifier are set by Dialogmakers
              International Ltd. Data could be collected and a profile created
              through the use of this function, which could, under certain
              circumstances, be traced back to an individual person. If the user
              does not want this, they can deactivate the link within the
              Dialogmakers International Ltd domain with a click. The user can
              also set up their browser in such a way as to generally exclude
              the use of cookies. We point out, however, that, in this case, the
              functionality of Dialogmakers International Ltd could be limited
              Information on the handling of personal data when using these
              websites can be taken from the respective provider’s privacy
              policy.
            </p>
          </div>
          <div className="pt-6">
            <h2 className="text-xl font-bold mb-2 text-center">Facebook</h2>

            <p className="mb-4 text-center">
              Facebooks’s privacy policy (operated by Facebook Inc., 1601 S.
              California Ave, Palo Alto, CA 94304, USA) can be found {""}
              <a
                className="text-blue-500"
                href=" https://www.facebook.com/about/privacy/."
                target="_blank"
              >
                here
              </a>
            </p>
          </div>
          <div className="pt-6">
            <h2 className="text-xl font-bold mb-2 text-center">Instagram</h2>

            <p className="mb-4 text-center">
              Instagram’s privacy policy (operated by Facebook Inc., 1601 S.
              California Ave, Palo Alto, CA 94304, USA) can be found {""}
              <a
                className="text-blue-500"
                href=" https://www.facebook.com/about/privacy/."
                target="_blank"
              >
                here
              </a>
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default DataSavingRule;
