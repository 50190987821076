import businessServicePng from '../../assets/images/Bussinessservice.jpg'
import Saturate from "../../components/Saturate";
import Wrapper from "../../components/Wrapper";
import Button from "../../components/Button";
import Heading from "../../components/Heading";
import { IoSettingsOutline } from "react-icons/io5";
import { MdOutlineChat } from "react-icons/md";
import { TiTickOutline } from "react-icons/ti";
import logo from "../../assets/logo1.png";
import feature4 from "../../assets/feature-4.jpg";
import feature5 from "../../assets/feature-5.jpg";
import { FaArrowRightLong, FaPersonArrowDownToLine } from "react-icons/fa6";

import { Parallax } from "react-parallax";
import { Autoplay } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { Fragment } from "react";
import useDevice from "../../useDevice";
// import businessGrowthIMG from '../../assets/images/businessGrowth.jpg'
// import businessGrowthIMG2 from '../../assets/images/businessGrowth2.jpg'
import { TbDeviceAnalytics } from "react-icons/tb";


const About = () => {
  const { isBigMobile } = useDevice();
  return (
    <Fragment>
      <Wrapper>
        <div className="grid lg:grid-cols-2 grid-cols-1 text-color-2 justify-between lg:gap-24 gap-16">
          <div className="relative w-full">
            <div>
              <div className="relative">
                <img src={businessServicePng} alt="dialogmakers-image" />
                {/* <Saturate /> */}
              </div>
              {/* <div className="md:absolute bottom-0 md:-right-6 mt-7">
                <div className="relative">
                  <img src={about2} alt="dialogmakers-image" />
                  <Saturate />
                </div>
              </div> */}
              {/* <div className="absolute lg:top-6 lg:right-12 top-0 right-[25%] -mt-[40px] md:mt-px">
                <img src={logo} alt="logo" width={150} className="border" />
              </div> */}
            </div>
          </div>
          <div className="">
            <TbDeviceAnalytics size={80} fill="#59DCE7" color="#2D3949"/>
            <Heading
              title=""
              heading="ARE YOU READY TO GROW YOUR BUSINESS"
              w="w-[80%]"
              color='black'
            />
            <p className="text-font text-color-3 py-4 text-sm">
              Boost your sales and enhance business performance in just 7 days with proven strategies! Act now to see real results fast and elevate your success!
            </p>
            <div className="md:flex items-center gap-16 mt-6 px-4 md:px-0">
              <div className="flex relative items-center gap-4 pb-6 md:pb-0">
                <div className="back-color-2 px-4 py-3.5">
                  <span className="invisible">D</span>
                </div>
                <div className="absolute top-0 -left-4 text-6xl text-color">
                  <IoSettingsOutline />
                </div>
                <span className="uppercase text-xl font-semibold w-[50%] text-black">
                  Business Growth
                </span>
              </div>
              <div className="flex relative items-center gap-4">
                <div className="back-color-2 px-4 py-3.5">
                  <span className="invisible">D</span>
                </div>
                <div className="absolute top-0 -left-4 text-6xl text-color">
                  <MdOutlineChat />
                </div>
                <span className="uppercase text-xl font-semibold w-[50%] text-black">
                  Marketing Solution
                </span>
              </div>
            </div>
            <div className="text-font mt-10">
              <ul className="text-sm">
                <li className="flex items-center gap-4">
                  <span className="back-color rounded-full text-lg text-white">
                    <TiTickOutline />
                  </span>
                  <span className="text-black75">
                    Unlock rapid business growth with tailored marketing solutions.
                  </span>
                </li>
                <li className="flex items-center gap-4 py-3">
                  <span className="back-color rounded-full text-lg text-white">
                    <TiTickOutline />
                  </span>
                  <span className="text-black75">
                    Transform your business with innovative marketing strategies.
                  </span>
                </li>
              </ul>
            </div>
            {/* <Button title="Discover More" divClass="mt-8" /> */}
          </div>
        </div>
        {/* <div className="pt-24">
          <div className="brand flex justify-center text-center items-center">
            <span className="tracking-widest font-medium text-color-3">
              1K+ BRANDS TRUST US
            </span>
          </div>
          <Swiper
            className="mt-16"
            breakpoints={{
              320: {
                slidesPerView: 1,
              },
              450: {
                slidesPerView: 2,
              },
              768: {
                slidesPerView: 3,
              },
              1024: {
                slidesPerView: 5,
              },
            }}
            modules={[Autoplay]}
            autoplay={{ delay: 2500, disableOnInteraction: false }}
            loop={true}
          >
            {[1, 2, 3, 4, 5, 6, 7].map((_, index) => {
              return (
                <SwiperSlide key={index}>
                  <div className="flex justify-center">
                    <img src={brand} alt="brand" />
                  </div>
                </SwiperSlide>
              );
            })}
          </Swiper>
          <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 mt-20 justify-items-center gap-8 xl:gap-0">
            <div className="relative overflow-hidden">
              <img src={feature1} alt="dialogmakers-image" />
              <Saturate />
              <div className="absolute brand-clip back-color -bottom-16 -left-10 md:py-32 md:px-24 py-28 px-20 rounded-full opacity-90">
                <span className="invisible">DialogMakers</span>
                <div className="absolute top-12 md:left-16 left-12 right-12">
                  <div className="back-color-2 inline-block p-5 rounded-full text-white">
                    <FaArrowRightLong />
                  </div>
                  <div className="uppercase font-semibold pt-2 text-gray-900 break-all">
                    <h5>Professional Development Services</h5>
                  </div>
                </div>
              </div>
            </div>
            <div className="relative overflow-hidden">
              <img src={feature1} alt="dialogmakers-image" />
              <Saturate />
              <div className="absolute brand-clip back-color -bottom-16 -left-10 md:py-32 md:px-24 py-28 px-20 rounded-full opacity-90">
                <span className="invisible">DialogMakers</span>
                <div className="absolute top-12 md:left-16 left-12 right-12">
                  <div className="back-color-2 inline-block p-5 rounded-full text-white">
                    <FaArrowRightLong />
                  </div>
                  <div className="uppercase font-semibold pt-2 text-gray-900 break-all">
                    <h5>Professional Development Services</h5>
                  </div>
                </div>
              </div>
            </div>
            <div className="relative overflow-hidden">
              <img src={feature1} alt="dialogmakers-image" />
              <Saturate />
              <div className="absolute brand-clip back-color -bottom-16 -left-10 md:py-32 md:px-24 py-28 px-20 rounded-full opacity-90">
                <span className="invisible">DialogMakers</span>
                <div className="absolute top-12 md:left-16 left-12 right-12">
                  <div className="back-color-2 inline-block p-5 rounded-full text-white">
                    <FaArrowRightLong />
                  </div>
                  <div className="uppercase font-semibold pt-2 text-gray-900 break-all">
                    <h5>Professional Development Services</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}
      </Wrapper>
      <Parallax
        bgImage={require("../../assets/images/businessGrowth.jpg")}
        bgImageAlt="dialogmakers-image"
        strength={300}
        className="relative"
      >
        <div className="lg:max-w-[1200px] mx-auto px-2">
          <div className="flex justify-center text-center py-20">
            <div className="z-[50] border-2 border-secondary py-16 border-neutral-600 p-6">
              <div className="flex justify-center z-[50]">
                <img src={logo} alt="logo" width={100} />
              </div>
              <div className="lg:text-5xl text-3xl md:text-4xl font-bold text-white py-10 flex justify-center">
                <div className="lg:w-[60%]">
                  <h1 className="leading-normal">
                    Improve Your Sales And Business Performance in ONLY 7 DAYS!
                  </h1>
                </div>
              </div>
              {/* <Button title="Discover More" /> */}
            </div>
          </div>
          <div className="absolute top-0 left-0 right-0 bottom-0 backdrop-brightness-50 bg-black/30"></div>
          <Saturate />
        </div>
      </Parallax>
      <div className="xl:max-w-[1200px] lg:max-w-[950px] md:max-w-[700px] mx-auto lg:mt-28 mt-20 px-4">
        <div
          className={`grid xl:grid-cols-2 grid-cols-1 place-content-center content-center gap-7 ${isBigMobile ? "pl-16 pr-16" : ""
            }`}
        >
          <div className="place-self-center">
            <Heading
              title="Business Growth"
              heading="READY TO GIVE A NEW BUSINESS LOOK"
              w="w-[70%]"
            />
            {/* <div className="py-6">
              <span className="text-2xl text-color font-semibold">
                PROIN EST LACUS, SAGITTIS LOBORTIS IACULISE GET.
              </span>
            </div> */}
            <p className="text-font text-color-3 text-sm mt-4">
              Ready to give your business a fresh, innovative look? It's time to revamp and elevate your brand to new heights.
            </p>
            <div className="md:flex items-center mt-10 gap-4">
              <div>
                <div className="text-5xl text-color">
                  <IoSettingsOutline />
                </div>
                <h4 className="text-xl font-semibold pt-2 pb-4">
                  MARKET MANAGEMENT
                </h4>
                <span className="text-font text-color-3 text-xs leading-loose">
                  Let's create a brand that not only stands out but sets you apart from the competition.
                </span>
              </div>
              <div className="mt-4 md:mt-0">
                <div className="text-5xl text-color">
                  <FaPersonArrowDownToLine />
                </div>
                <h4 className="text-xl font-semibold pt-2 pb-4">
                  BUSINESS ANALYSIS
                </h4>
                <span className="text-font text-color-3 text-xs leading-loose">
                  Your new business look is just the beginning of your next success story!
                </span>
              </div>
            </div>
          </div>
          <div className="grid md:grid-cols-2 grid-cols-1 place-items-center justify-items-center gap-6">
            <div className="lex w-full h-full bg-primary relative">
              <span className="uppercase absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] text-xl text-gray-800 font-semibold  tracking-widest text-center text-white">
                Explore Marketing Services
              </span>
            </div>
            <div className="w-full relative flex justify-center">
              <img src={feature4} alt="dialogmakers-image" className="w-full" />
              <Saturate />
            </div>
            <div className="w-full relative">
              <img src={feature5} alt="dialogmakers-image" className="w-full" />
              <Saturate />
            </div>
            <div className="flex w-full h-full bg-black text-color relative">
              <span className="uppercase absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] text-xl font-semibold  tracking-widest text-center text-white">
                Get a professional solutions
              </span>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default About;
