import { Fragment } from "react";
import Landing from "../layouts/HomePage/Landing";
import Services from "../layouts/HomePage/Services";
import About from "../layouts/HomePage/About";
import Portfolio from "../layouts/HomePage/Portfolio";
import ChooseUs from "../layouts/HomePage/ChooseUs";
import ContactUs from "../layouts/HomePage/ContactUs";
import "../CSS/home.css";

const HomePage = ({ cursorRef }) => {
  return (
    <Fragment>
      <Landing cursorRef={cursorRef} />
      <Services />
      <About />
      <Portfolio />
      <ChooseUs />
      <ContactUs />
    </Fragment>
  );
};

export default HomePage;
