import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import App from "./App";
import HomePage from "./pages/HomePage";
import Contact from "./pages/Contact";
import Team from "./pages/Team";
import Pricing from "./pages/Pricing";
import AboutPage from "./pages/AboutPage";
import Portfolio from "./pages/Portfolio";
import Services from "./pages/Services";
import Imprint from "./pages/Imprint";
import DataSavingRule from "./pages/DataSavingRule";
import TermsOfService from "./pages/TermsOfService";
import Shop from "./pages/Shop";


const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    children: [
      {
        path: "/",
        element: <HomePage />,
      },
      {
        path: "/contact",
        element: <Contact />,
      },
      {
        path: "/services",
        element: <Services />,
      },
      {
        path: "/team",
        element: <Team />,
      },
      {
        path: "/pricing",
        element: <Pricing />,
      },
      {
        path: "/portfolio",
        element: <Portfolio />,
      },
      {
        path: "/about-us",
        element: <AboutPage />,
      },
      {
        path: "/shop",
        element: <Shop />,
      },
      {
        path: "/imprint",
        element: <Imprint />,
      },
      {
        path: "/data-saving-rule",
        element: <DataSavingRule />,
      },
      {
        path: "/terms-and-condition",
        element: <TermsOfService />,
      },
    ],
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);
