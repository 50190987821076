import PageInto from "../components/PageIntro";
import { useInView } from "framer-motion";
import { useRef } from "react";
import { portfolio } from "../static/portfolio";

export default function Portfolio() {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });
  return (
    <>
      <PageInto pageName={"Portfolio"} />
      <span className="flex mx-auto my-8 justify-center">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 64 64"
          stroke-width="2.5"
          stroke="#2D3949"
          fill="none"
          class="duration-300 transform transition-all"
          style={{ width: "48px" }}
        >
          <circle
            cx="33.52"
            cy="39.9"
            r="20.42"
            stroke-linecap="round"
          ></circle>
          <circle
            cx="33.52"
            cy="39.9"
            r="15.22"
            stroke-linecap="round"
          ></circle>
          <path
            d="M33.28 30.49L36 36a.1.1 0 00.08.05l6 .88a.1.1 0 01.06.17l-4.38 4.27a.08.08 0 000 .09l1 6a.09.09 0 01-.14.1l-5.42-2.84a.08.08 0 00-.09 0l-5.41 2.84a.1.1 0 01-.15-.1l1-6a.14.14 0 000-.09l-4.38-4.27a.1.1 0 01.05-.17l6.1-.93a.08.08 0 00.07-.05l2.71-5.49a.1.1 0 01.18.03z"
            stroke-linecap="round"
          ></path>
          <path d="M21.48 23.75L9.89 3.67h10.08l9.07 15.71-9.07-15.71"></path>
          <path
            stroke-linecap="round"
            d="M44.82 22.89l11.1-19.22H45.79l-9.02 15.71"
          ></path>
        </svg>
      </span>
      <span className="flex text-black50 my-4 mx-auto justify-center">
        Our Projects & Achievments
      </span>
      <h2 className="font-bold text-black uppercase md:text-h3 phone:text-h5  text-center max-w-[600px] mx-auto mt-4">
        A Journey Through Our Finest Work with Projects and Achievements
      </h2>
      <div className="flex flex-wrap justify-center my-16 mx-auto max-w-[1280px]" ref={ref}>
        {portfolio?.map((val, index) => (
          <div
          key={index}
            style={{
              transform: isInView ? "none" : `translateY(100px)`,
              opacity: isInView ? 1 : 0,
              transition: "all 0.5s cubic-bezier(0.17, 0.55, 0.55, 1) 0.3s",
              transitionDelay: `${index / 2}s`
            }}
            className="flex border w-[336px] h-[336px] overflow-hidden relative m-5 hover:shadow-xl transition-all duration-500">
            <img
              className="w-auto h-full absolute"
              src={val.img}
              alt=""
            />
            <div className="flex absolute bg-[#000000] bg-opacity-50 bottom-0 p-4 w-full">
              <span className="text-white">{val.name}</span>
            </div>
          </div>
        ))}
      </div>
    </>
  );
}
