import temp1 from './shopAssets/template/temp-1.png'
import temp2 from './shopAssets/template/temp-2.png'
import temp3 from './shopAssets/template/temp-3.png'
// books 
import book1 from './shopAssets/books/book1.png'
import book2 from './shopAssets/books/book2.png'
import book3 from './shopAssets/books/book3.png'

export const shopData = [
    {
        preview: temp1,
        name: 'Ribbon - Creative Blogs for writers',
        key1: 'Clean and professional with modern color scheme.',
        key2: 'Includes everything you need to showcase your skills and work.',
        key3: 'Have profession looking layouts ',
        price: '8',
        ratings: '4',
    },
    {
        preview: temp2,
        name: 'Odrin- books writing theme for writers',
        key1: 'Clean and professional with modern color scheme.',
        key2: 'Includes everything you need to showcase your skills and work.',
        key3: 'Have profession looking layouts ',
        price: '8',
        ratings: '4',
    },
    {
        preview: temp3,
        name: 'Dora - News articles hybrid Html CSS template',
        key1: 'Clean and professional with modern color scheme.',
        key2: 'Includes everything you need to showcase your skills and work.',
        key3: 'Have profession looking layouts ',
        price: '8',
        ratings: '4',
    }
]

export const books = [
    {
        cover: book1,
        name: 'Taiwan [UN]Limited: My Life After an Aortic',
        writer: 'Eric Ronnau',
        price: '4',
        link: '',
        stars: '4',
        totalRatings: '20',
        purchase: '25',
        description: `Hi, I'm Eric R., and this is the extraordinary story of how my life took an unexpected turn after a life-threatening aortic dissection. Born on a crisp September day in 1982 in Kiel, Germany, my journey led me through the highs and lows of life in Germany and Spain, before I embarked on a profound journey of healing and self-discovery in Taiwan.`,
    },
    {
        cover: book2,
        name: 'Taiwan [UN]Limited - Mein Leben nach der Aortendissektion: Wachstum und Wandel',
        writer: 'Eric Ronnau',
        price: '5',
        link: '',
        stars: '4',
        totalRatings: '18',
        purchase: '23',
        description: `Hi, I'm Eric R., and this is the extraordinary story of how my life took an unexpected turn after a life-threatening aortic dissection. Born on a crisp September day in 1982 in Kiel, Germany, my journey led me through the highs and lows of life in Germany and Spain, before I embarked on a profound journey of healing and self-discovery in Taiwan.`,
    },
    {
        cover: book3,
        name: 'Paella scheissen: Urlaub mit Katastrophengarantie (Arschhaare Rauchen 3)',
        writer: 'Eric Ronnau',
        price: '4',
        link: '',
        stars: '4',
        totalRatings: '12',
        purchase: '15',
        description: `Hi, I'm Eric R., and this is the extraordinary story of how my life took an unexpected turn after a life-threatening aortic dissection. Born on a crisp September day in 1982 in Kiel, Germany, my journey led me through the highs and lows of life in Germany and Spain, before I embarked on a profound journey of healing and self-discovery in Taiwan.`,
    }
]