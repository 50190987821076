import FT from '../assets/projects/FT.jpg'
import MC from '../assets/projects/MC.jpg'
import MP from '../assets/projects/MP.jpg'
import TM from '../assets/projects/TM.jpg'
import TMM from '../assets/projects/TMM.jpg'

export const portfolio = [
    {
        img: TM,
        name: 'Mortgage Releive'
    },
    {
        img: TMM,
        name: 'Taiwan Mia'
    },
    {
        img: MP,
        name: 'MAZU Production'
    },
    {
        img: FT,
        name: 'FlirtTool'
    },
    {
        img: MC,
        name: 'MAZU Commnunites'
    },

]