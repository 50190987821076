import { IoCartOutline } from "react-icons/io5";
import shopVector from '../assets/icons/shopVector.png'
import { FaWordpressSimple } from "react-icons/fa6";
import { RiHtml5Line } from "react-icons/ri";
import { BsPlugin } from "react-icons/bs";
import { RiBook2Line } from "react-icons/ri";
import { shopData } from "../shopdata";
import { IoStarOutline } from "react-icons/io5";
import { IoStar } from "react-icons/io5";
import { books } from "../shopdata";
import logo from '../assets/logo2.png'
import { FaArrowLeft } from "react-icons/fa6";
import { Link } from "react-router-dom";


export default function Shop() {
    return (
        <>
            <header className="flex items-center justify-between h-16 px-8 overflow-hidden">
                <figure className="w-[200px]">
                    <img src={logo} alt="" />
                </figure>
                <div className="flex items-center gap-4 p-1 text-base border-2 border-black rounded-full cursor-pointer">
                    <Link to={'/'}><FaArrowLeft size={22} /></Link>
                </div>
            </header>
            <section className="bg-[#01333F] text-white py-16">
                <div className="flex items-center max-w-[1280px] xl:mx-auto sm:mx-8 phone:mx-4">
                    <div className="flex flex-col relative">
                        <span className="flex border items-center rounded-full gap-2 w-max px-4 font-bold">
                            <IoCartOutline size={32} />
                            Store
                        </span>
                        <h1 className="xl:text-h1 lg:text-h2 phone:text-h3 w-[80%] font-bold leading-tight z-10">
                            DialogMakers International Ltd.
                        </h1>
                        <small className="text-base font-light my-4">
                            Discover & Elevate Your Experience with Our Premium Digital Assets & Books
                        </small>
                        <figure className="phone:flex md:hidden w-[260px] absolute right-0 top-0 opacity-30">
                            <img src={shopVector} alt="" />
                        </figure>
                    </div>
                    <figure className="md:flex phone:hidden w-[500px]">
                        <img src={shopVector} alt="" />
                    </figure>
                </div>
            </section>
            <section className="flex md:justify-center phone:text-left px-4 items-center text-nowrap flex-nowrap text-white bg-[#015A65] lg:gap-16 sm:gap-8 phone:gap-4 md:py-6 phone:py-4 overflow-x-auto">
                <div className="flex gap-2 lg:text-xl sm:text-lg phone:text-sm items-center">
                    <FaWordpressSimple size={40} />
                    <span>Wordpress themes</span>
                </div>
                <div className="flex gap-2 lg:text-xl sm:text-lg phone:text-sm items-center">
                    <RiHtml5Line size={40} />
                    <span>HTML Templates</span>
                </div>
                <div className="flex gap-2 lg:text-xl sm:text-lg phone:text-sm items-center">
                    <BsPlugin size={40} />
                    <span>Plugins</span>
                </div>
                <div className="flex gap-2 lg:text-xl sm:text-lg phone:text-sm items-center">
                    <RiBook2Line size={40} />
                    <span>Books</span>
                </div>
            </section>
            {/* Content  */}
            {/* for WP themes  */}
            <section className="flex flex-col justify-center xl:max-w-[1100px] phone:w-[90%] xl:mx-auto sm:mx-8 phone:mx-4 my-16 gap-12">
                {shopData?.map(val => (
                    <>
                        <div className="flex lg:flex-row phone:flex-col gap-6 overflow-hidden relative">
                            <div className="flex md:flex-row phone:flex-col gap-6 lg:w-[80%]">
                                <figure className="w-[413px] h-[210px] rounded-lg shadow-xl shadow-gray-200 overflow-hidden">
                                    <img src={val.preview} alt="" className="h-full" />
                                </figure>
                                <div className="flex flex-col pt-4 md:w-[70%]">
                                    <h5 className="text-2xl font-bold line-clamp-2">{val.name}</h5>
                                    <small className="text-gray-500">By DialogMakers</small>
                                    <ul className="my-4 text-sm flex flex-col gap-2 px-4 list-disc">
                                        <li>{val.key1}</li>
                                        <li>{val.key2}</li>
                                        <li>{val.key3}</li>
                                    </ul>
                                    <div className="flex items-center gap-2">
                                        <a href="" className="w-max p-1 px-4 bg-blue-400 rounded-lg text-white font-semibold">
                                            Preview
                                        </a>
                                        <a href="" className="w-max p-1 px-4 bg-[#88e64a] rounded-lg text-black font-semibold">
                                            Buy on Envato
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="flex flex-col lg:w-[20%] phone:w-full gap-4 p-4 phone:border-l-0 phone:border-t lg:border-t-0 lg:border-l">
                                <strong className="text-h4">$ {val.price}</strong>
                                <span className="flex items-center gap-2">
                                    <IoStar size={22} color="#DEBA09" />
                                    <IoStar size={22} color="#DEBA09" />
                                    <IoStar size={22} color="#DEBA09" />
                                    <IoStar size={22} color="#DEBA09" />
                                    <IoStar size={22} color="white" strokeWidth={8} stroke="gray" />
                                </span>
                                <strong className="text-gray-500">12 Sales</strong>
                            </div>
                        </div>

                    </>
                ))}
            </section>
            {/* for Books  */}
            <section className="flex flex-col justify-center xl:max-w-[1100px] phone:w-[90%] xl:mx-auto sm:mx-8 phone:mx-4 my-16 gap-12">
                {!books?.map(book => (
                    <div className="flex lg:flex-row phone:flex-col gap-6 overflow-hidden relative">
                        <div className="flex md:flex-row phone:flex-col gap-6 lg:w-[70%]">
                            <figure className="flex w-[200px] shadow-xl mx-auto rounded-xl overflow-hidden">
                                <img src={book.cover} alt="" />
                            </figure>
                            <div className="flex flex-col gap-2 pt-4 md:w-[70%]">
                                <h5 className="text-2xl font-bold line-clamp-2">{book.name}</h5>
                                <small>By {book.writer}</small>
                                <strong className="text-h4">$ {book.price}</strong>
                                <a href={book.link} className="w-max p-1 px-4 bg-blue-400 rounded-lg text-white font-semibold">Buy on amazon</a>
                            </div>
                        </div>
                        <div className="flex flex-col lg:w-[20%] phone:w-full gap-4 p-4 phone:border-l-0 phone:border-t lg:border-t-0 lg:border-l">
                            <strong>Ratings</strong>
                            <span className="flex items-center gap-2">
                                <IoStar size={22} color="#DEBA09" />
                                <IoStar size={22} color="#DEBA09" />
                                <IoStar size={22} color="#DEBA09" />
                                <IoStar size={22} color="#DEBA09" />
                                <IoStar size={22} color="white" strokeWidth={8} stroke="gray" />
                            </span>
                            <span className="flex gap-2 font-bold text-blue-500">
                                <small>{book.purchase} Purchase</small>
                                <small>{book.totalRatings} ratings</small>
                            </span>
                            <p className="text-sm text-gray-600 line-clamp-6">
                                {book.description}
                            </p>
                        </div>
                    </div>
                ))}
            </section>
        </>
    )
}