import React from "react";
import { Link } from "react-router-dom";
import PageInto from "../components/PageIntro";

const Imprint = () => {
  return (
    <>
      <PageInto pageName={"Imprint"} />

      <div className="xl:max-w-[1200px] md:max-w-[700px] lg:max-w-[950px] mx-auto px-4 py-8">
        <div className="">
          <br />
          <p className="mb-4 text-center">Dialogmakers International Ltd.</p>
          <p className="mb-4 text-center">
            71-75 Shelton Street, Covent Garden, London, WC2H 9JQ
          </p>

          <p className="mb-4 text-center text-blue-500">
            <span className="font-bold text-black">Email :</span>{" "}
            support@dialogmakers-international.com
          </p>
          <div className="pt-6 mb-4">
            <h1 className="text-xl text-center font-bold">
              General Terms and Conditions
            </h1>

            <p className="text-center">
              You can find our General Terms and Conditions
              <Link to="/terms-and-condition" className="ml-1 text-blue-500">
                here
              </Link>
            </p>
          </div>

          <h1 className="text-xl text-center font-bold pt-6 mb-4">
            Liability
          </h1>

          <p className="mb-4">
            In the court decision, 312 O 85/98 - 'Liability for links' from 12
            May 1998 the county court in Hamburg resolved that, should links be
            included on a website, then the requisite site is thereby
            responsible for the content displayed on the linked site. To prevent
            this liability, it is necessary to expressly disassociate oneself
            from this content of these links. For this reason we, hereby, wish
            to ascertain, that we most decidedly do not endorse the content on
            these linked sites. This disclaimer applies to all links which
            appear on our website and to the complete content of these websites;
            including those underlying the displayed banners. These services
            contain links to other internet sites, to resources and to other
            users of those services. With this disclaimer we would like to state
            that we are also not in any way responsible for the availability of
            the external resources, or for their content; for the content of
            which we do not necessarily approve. In addition, we claim no
            responsibility for the advertising content, the products or for
            other matters which appear on these websites. Under no circumstances
            are we responsible for any loss, or damage, caused by the use of the
            content, the goods, or the services of these sites; we can be made
            neither directly, nor indirectly, liable for these. All issues
            relating to external links should be referred to the relevant site
            administrator or webmaster. We also wish to disassociate ourselves
            from offensive, illegal, morally unethical or objectionable content.
            We would be extremely grateful to you, if you would inform us,
            should you be confronted with such content. All rights, including
            those concerning the duplication of materials, copyright,
            publication and translation, are reserved. None of the materials
            publicised may be duplicated in any form (as photocopies, microfilms
            or used within other processes) without the written permission of
            the author; this includes duplication or distribution by electronic
            means.
          </p>
        </div>
      </div>
    </>
  );
};

export default Imprint;
