import React from "react";
import PageInto from "../components/PageIntro";

const TermsOfService = () => {
  return (
    <>
      <PageInto pageName={"Terms & Conditions"} />

      <div className="xl:max-w-[1200px] md:max-w-[700px] lg:max-w-[950px] mx-auto px-4 py-8">
        <div className="">
          <br />
          <p className="mb-4 text-center">Dialogmakers International Ltd.</p>
          <p className="mb-4 text-center">
            71-75 Shelton Street, Covent Garden, London, WC2H 9JQ
          </p>

          <p className="mb-4 text-center text-blue-500">
            <span className="font-bold text-black">Email :</span>{" "}
            support@dialogmakers-international.com
          </p>

          <div className="pt-6">
            <h2 className="text-xl font-bold mb-2 text-center">
              Scope of Application
            </h2>

            <p className="mb-4 text-center">
              <span className="font-bold">1.1</span> The following General Terms
              and Conditions is an integral part of every contract concluded by
              means of the Dialogmakers International Ltd. smartphone app or the
              website Dialogmakers International Ltd. (hereinafter referred to
              as "Service") between Dialogmakers International Ltd., 71-75
              Shelton Street, Covent Garden, London, WC2H 9JQ, and the User
            </p>
            <p className="mb-4 text-center">
              <span className="font-bold">1.2</span> The Operator provides its
              services exclusively on the basis of these General Terms and
              Conditions.
            </p>
            <p className="mb-4 text-center">
              <span className="font-bold">1.3</span> Persons who have not yet
              reached the age of 18, or who are in guardianship or a care
              relationship in whole or in part and thus do not have full legal
              capacity may not use the Service. The User confirms and accepts
              these regulations with his/her registration. In case of violation
              or contravention of the regulations, the respective user account
              will be deleted without consultation and regardless of any
              remaining credit balances. The same regulation appdives in the
              case of the use of the Service for commercial purposes. The user
              account will also be deleted by the Operator in such cases.
            </p>
          </div>
       
          <div className="pt-6">
            <h2 className="text-xl font-bold mb-2 text-center">2.0 Conclusion of the Contract</h2>

            <p className="mb-4 text-center">
            The contract between the Operator and the User shall be concluded by the creation of a user account by means of the free registration of the User on the website or by means of the respective mobile apps.
            </p>
          </div>
          <div className="pt-6">
            <h2 className="text-xl font-bold mb-2 text-center">3.0  Subject Matter of the Contract</h2>

            <p className="mb-4 text-center">
              <span className="font-bold">3.1</span> The Service provided by the Operator offers the User the opportunity to communicate interactively and engage in ondivne dating with other users. To this end, the Operator offers users access to its database and the user profiles that are stored in it. Users can view these profiles, add their own profile to the database, and contact other users. Contact and communication between users shall be conducted by means of the Service.
            </p>
            <p className="mb-4 text-center">
              <span className="font-bold">3.2</span> The Operator shall only be responsible for the attempt to estabdivsh contact with other users and does not guarantee that contact will actually be estabdivshed.
            </p>
            <p className="mb-4 text-center">
              <span className="font-bold">3.3</span> The use of the basic Service is free of charge; only the individual services which are additionally offered by the Operator are subject to a charge. A detailed description of these services is provided within the Service itself.
            </p>
            <p className="mb-4 text-center">
              <span className="font-bold">3.5</span> Should the User wish to use a chargeable service and pay by means of fdivrts, the Operator will inform the User how many Fdivrts will be charged for the use of the respective service.
            </p>
            <p className="mb-4 text-center">
              <span className="font-bold">3.6</span> In order to guarantee users an optimal service experience and ensure availabidivty of the Service at all times, the Operator employs professional entertainers and profile attendants to look after and entertain users. Such persons are not identified in a special manner by the system. Users can only send messages to and conduct dialogues with such individuals within the Service; they cannot meet them in person.
            </p>
          </div>
       
        </div>
      </div>
    </>
  );
};

export default TermsOfService;
